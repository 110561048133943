export const TechnologyMax = {
    partner: [
      {
        "orgName": "Accion",
        "link": "https://us.accion.org/",
        "name":"Fabiana Estrada",
        "email":"festrada@accioneast.com",
        "phone": "",
        "comments":"",
        "keywords":["Loans", "Hispanic", "Spanish", "Small Business", "Entrepreneurs", "Flexible Capital", "Training", "Acces to Capital"]
      },
      {
        "orgName": "BBFI - Black Business Investment Fund",
        "link": "https://bbifflorida.com/",
        "name":"Danielle St. Luce",
        "email":"DStluce@bbif.com",
        "phone": "",
        "comments":"Specialized, but not limited to, lending to black and minority owned companies.",
        "keywords":["Loans", "African American", "Black, Minority", "Small Business", "Lender Entrepreneurs", "Flexible Capital", "Acces to Capital", "Packaging"]
      },
      {
        "orgName": "Miami DDA - Miami Downtown Development Authority",
        "link": "http://www.miamidda.com/",
        "name":"Kevin Ruiz",
        "email":"ruiz@miamidda.com",
        "phone": "",
        "comments":"",
        "keywords":["Grants", "Small Business", "advocate", "Planning", "capital improvements", "Marketing", "Entrepreneurs" ]
      },
      {
        "orgName": "Branches",
        "link": "https://branchesfl.org/",
        "name":"Karla Bachmann",
        "email":" Kbachmann@branchesfl.org",
        "phone": "",
        "comments":"",
        "keywords":["e-commerces", "Branding", "Distribution", "Growth", "Marketing"]
      },
      {
        "orgName": "Miami-Dade Chamber of Commerce",
        "link": "https://m-dcc.org/",
        "name":"Matthew Pigatt",
        "email":"mpigatt@m-dcc.org",
        "phone": "",
        "comments":"Specialized, but not limited to, lending to black and minority owned companies.",
        "keywords":["Loans", "Micro-Loans", "Access to Capital", "Lenders", "Flexible Capital", "Grants", "Growth", "Networking", "Minority", "Black", "African-American"]
      },
      {
        "orgName": "Opa-loccka CDC - Opa-locka Community Development Corporation",
        "link": "http://www.olcdc.org/",
        "name":"Michael John Grenn",
        "email":"mjfreen@olcdc.org",
        "phone": "",
        "comments":"Servicing Miami Gardens, Liberty City and Opa-Locka, North Miami",
        "keywords":["Loans", "Lenders", "Access to Capital", "Loan Packaging",  "Micro-Loans","Miami Gardens", "Liberty City", "Opa-locka", "North Miami"]
      },
      {
        "orgName": "NANA -  Neighbors and Neighbors Association",
        "link": "",
        "name":"Robert Jones",
        "email":"rjones@nanafl.org",
        "phone": "(305) 756-0605",
        "comments":"",
        "keywords":["Grants", "Small Business", "Entrepreneurs", "Loan Packaging", "Growth"]
      },
      {
        "orgName": "Legal Services of Greater Miami / Florida Community Development Legal Project",
        "link": "https://www.flcommunitydevelopment.org/",
        "name":"Shahrzad Emami ",
        "email":"semami@legalservicesmiami.org",
        "phone": "",
        "comments":"interested small businesses would need to qualify for our services but can apply directly on our FCDL website.   Interested businesses should go to https://www.flcommunitydevelopment.org/ and click on Apply for Services.",
        "keywords":["Loan Closings", "Documents", "Legal Review", "Small Business", "Legal"]
      },
      {
        "orgName": "Miami Bayside Foundation",
        "link": "https://miamibaysidefoundation.org/",
        "name": "Joann Milord",
        "email": "joann@mbf.miami",
        "phone": "",
        "comments":"",
        "keywords": ["Haitian Creole Speaking", "Spanish Speaking", "Micro Loans", "Loan Packaging", "Growth Marketing", "Procurement"]
      },
    ],
  }
  
  export default TechnologyMax
  